$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';







































































.nice-icon__arrow_circle-34,
.nice-icon__check-34,
.nice-icon__draft-34,
.nice-icon__error-34,
.nice-icon__pause-34,
.nice-icon__pencil-34,
.nice-icon__play-34,
.nice-icon__plus-34,
.nice-icon__see-34,
.nice-icon__warn-34,
.nice-icon__dashboard {
	height: 34px; }

.nice-icon__arrow_circle-24,
.nice-icon__arrow_corner-24,
.nice-icon__basket-24,
.nice-icon__cancel-24,
.nice-icon__check-24,
.nice-icon__draft-24,
.nice-icon__error-24,
.nice-icon__load-24,
.nice-icon__pause-24,
.nice-icon__pencil-24,
.nice-icon__play-24,
.nice-icon__plus-24,
.nice-icon__see-24,
.nice-icon__warn-24,
.nice-icon__copy-24 {
	height: 24px; }

.nice-icon__minus_another-20,
.nice-icon__plus-20,
.nice-icon__plus_another-20,
.nice-icon__money,
.nice-icon__man, {
	height: 20px; }

.nice-icon__bell {
	height: 18px; }

.nice-icon__monitor-17,
.nice-icon__plus-17 {
	height: 17px; }

.nice-icon__arrow_circle,
.nice-icon__arrow_circle_2,
.nice-icon__audio,
.nice-icon__check,
.nice-icon__diagram,
.nice-icon__draft,
.nice-icon__error,
.nice-icon__hint,
.nice-icon__pause,
.nice-icon__play,
.nice-icon__warn {
	height: 16px; }

.nice-icon__load,
.nice-icon__melody-15,
.nice-icon__mark_crossed-15 {
	height: 15px; }

.nice-icon__arrow-14,
.nice-icon__calendar-14,
.nice-icon__basket-14,
.nice-icon__resolution-14,
.nice-icon__mark-14 {
	height: 14px; }

.nice-icon__monitor-13,
.nice-icon__pencil-13,
.nice-icon__play-13 {
	height: 13px; }

.nice-icon__bill,
.nice-icon__arrow_corner-12 {
	height: 12px; }

.nice-icon__x {
	height: 9px; }
