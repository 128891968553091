$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';

























































































































































$border-width: $ni_button-border-width;
$border-radius: $ni_button-border-radius;
$pad-x: $ni_button-padding-x - $border-width;
$pad-y: $ni_button-padding-y - $border-width;

.nice-button {
	@extend %button__text;

	--default-color: var(--main_color);
	--disabled-color: var(--disabled_color);
	--hover-color: var(--main_lighter_color);
	--active-color: var(--main_color);
	--contrast-color: #ffffff;

	--pad-x: #{$pad-x};
	--pad-y: #{$pad-y};
	--border-width: #{$border-width};
	--border-radius: #{$border-radius};

	position: relative;
	z-index: 0;

	display: inline-flex;
	min-height: 31px;
	box-sizing: border-box;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	color: var(--default-color, currentColor);
	font-size: $fsz__small;
	font-weight: 500;

	padding: var(--pad-y, #{$pad-y}) var(--pad-x, #{$pad-x});
	border-radius: var(--border-radius, #{$border-radius});

	overflow: hidden;


	&:hover {
		color: var(--hover-color, currentColor); }

	&:active {
		color: var(--active-color, currentColor); }

	&:disabled {
		color: var(--disabled-color, currentColor); }


	&.__bordered {
		border: var(--border-width, #{$border-width}) solid currentColor; }

	&.__watercolor {
		border: var(--border-width, #{$border-width}) solid transparent;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;

			background-color: currentColor;
			opacity: .2; } }

	&.__filled {
		border: var(--border-width, #{$border-width}) solid transparent;
		color: var(--contrast-color, #ffffff);
		background-color: var(--default-color);

		&:hover {
			background-color: var(--hover-color);
			border-color: var(--hover-color); }

		&:active {
			background-color: var(--active-color);
			border-color: var(--active-color); }

		&:disabled {
			background-color: var(--disabled-color);
			border-color: var(--disabled-color); } }




	& > *:not(:first-child) {
		margin-left: .8em; }



	// themes
	&.default {
		--default-color: var(--main_color);
		--disabled-color: var(--disabled_color);
		--hover-color: var(--main_lighter_color);
		--active-color: var(--main_color); }

	&.gray {
		--default-color: #{$nice_color-graysemidark};
		--disabled-color: #{$nice_color-graysemidark__disabled};
		--hover-color: #{$nice_color-gray};
		--active-color: #{$nice_color-graysemidark}; }

	&.red {
		--default-color: #{$nice_color-red};
		--disabled-color: #{$nice_color-red__disabled};
		--hover-color: #{$nice_color-red};
		--active-color: #{$nice_color-red}; }

	&.blue {
		--default-color: #{$nice_color-blue};
		--disabled-color: #{$nice_color-activebutton};
		--hover-color: #{$nice_color-bluelight};
		--active-color: #{$nice_color-blue}; }

	&.bluedark,
	&.darkblue {
		--default-color: #{$nice_color-blue_dark};
		--disabled-color: #{$nice_color-blue_dark__disabled};
		--hover-color: #{$nice_color-blue};
		--active-color: #{$nice_color-blue_dark}; } }

