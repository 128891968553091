 :root {
	font-family: $font-main;
	font-size: $fsz__base;
	min-height: 100%;
	height: 100%; }

body {
	min-height: 100%;
	margin: 0;
	padding: 0;

	color: var(--text_color);
	font-size: $fsz__base;
	font-family: $font-main;

	&::before {
		content: '';
		display: table; } }

h1, h2, h3, h4, h5, h6 {
	margin: 40px / 30px * 1rem 0;
	padding: 0;

	font-weight: 300;
	font-family: $font-title; }

h1 {
	font-size: $fsz__new__title; }

h2 {
	font-size: $fsz__new__section-title; }

h3 {
	font-size: $fsz__new__large; }

h4 {
	font-size: $fsz__new__normal; }

h5 {
	font-size: $fsz__new__middle; }

h6 {
	font-size: $fsz__new__smaddle; }

hr {
	margin: 15.5px 0;

	border: none;
	border-bottom: 1px solid $nice_color-gray_light; }

ul {
	box-sizing: border-box; }

code,
pre {
	font-family: $font-monospace;
	font-size: 0.6rem;
	tab-size: 4; }

.code-preview {
	padding: 5px 10px;
	box-sizing: border-box;

	background-color: $nice_color-gray_light;
	border-radius: $border-radius-min;
	font-family: $font-monospace;
	font-size: 0.6rem;
	tab-size: 4; }

.nobr {
	white-space: nowrap; }

.title-error {
	font-size: 3em;
	font-weight: 100; }

// https://allyjs.io/tutorials/hiding-elements.html
.visuallyhidden:not(:focus):not(:active) {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden; }


/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: currentColor transpsrent; }


/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 6px;
	height: 6px; }

*::-webkit-scrollbar-track {
	background: transparent; }


*::-webkit-scrollbar-thumb {
	width: 4px;
	background-color: currentColor;
	opacity: .2;

	&:hover {
		opacity: 1; } }
