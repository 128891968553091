$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';

























































































































































































































































































































































































































































































































































































$height: 30px;

.stat-graph {
	display: grid;
	grid-template-columns: 30px 40px auto;
	grid-template-rows: auto 30px;
	grid-template-areas: "label legend-y graph" ". . legend-x";
	position: relative;
	overflow: hidden;
	padding-bottom: 50px; }

.label {
	grid-area: label;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative; }

.label-text {
	transform: rotate(-90deg);
	display: block;
	text-transform: uppercase;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	color: #919191;
	white-space: nowrap; }

.legend-y {
	grid-area: legend-y;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-end;

	>span {
		text-transform: uppercase;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 12px;
		color: #919191;
		white-space: nowrap; } }

.graph {
	grid-area: graph;
	position: relative; }

.bottomLegend {
	grid-area: legend-x;
	display: flex;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	text-transform: uppercase;
	color: #919191;
	position: absolute; }

.legend-element {
	text-align: center; }

// HACK: for the first and last labels
.stat-graph.lines {

	.legend-element {
		&:first-child {
			transform: translateX(50%);
			text-align: left; }
		&:last-child {
			transform: translateX(-50%);
			text-align: right; } } }

.hit-zone {
	fill: rgb(255,0 ,0 );
	opacity: 0; }

.tooltip {
	will-change: top,left;
	position: absolute;
	top: 100px;
	left: 100px;
	width: 140px;
	background-color: #FFFFFF;
	border-radius: 10px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	padding: 10px;
	pointer-events: none;
	transition: top .2 ease, left .2 ease;
	z-index: 1;

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 15px;
		color: #000000;
		margin: 0; }

	.content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 0; }

	.label {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;
		color: #6240D9; }

	::v-deep .value {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;
		text-align: left;

		&.max-bid-value {
			color: rgb(45, 195, 183);
			margin-top: 10px; } } }

.vert {
	opacity: .25;

	&.selected {
		opacity: 1; } }

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start; }

.header-date {
	width: 51px;
	height: 24px;

	font-size: 10px;
	line-height: 12px;
	text-align: right;

	color: #919191; }

.max-bid {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-top: 15px;
	align-items: flex-end; }

.max-bid-label {
	width: 65px;
	height: 13px;

	font-size: 11px;
	line-height: 13px;
	/* identical to box height */

	color: #2DC3B7; }

.max-bid-diff {
	text-align: right; }

.old-bid {
	width: 65px;
	height: 25px;

	font-size: 9px;
	line-height: 11px;
	text-align: right;
	text-decoration-line: line-through;

	color: #000000; }

.new-bid {
	width: 65px;
	height: 25px;

	font-size: 11px;
	line-height: 13px;
	text-align: right;
	font-weight: bold;

	color: #000000; }

.bid-footer {
	margin-top: 20px; }

.bid-author {
	width: 122px;
	height: 24px;

	font-size: 10px;
	line-height: 12px;

	color: #919191; }
