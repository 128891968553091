$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';




















































































































































































































































































































































































































































.nice-input {
	--default-color: var(--text_2_color);
	--hover-color: var(--text_2_color);
	--active-color: var(--text_2_color);
	--disabled-color: var(--disabled_color);
	--text-color: var(--text_color);

	position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	box-sizing: border-box;

	border-bottom: 1px solid var(--default-color);

	color: var(--default-color);

	&.invalid {
		--default-color: #{$nice_color-red}; }


	&.label_holder {
		padding-top: $ni-label-height; }

	::v-deep .nice-label .ni_label--label {
		color: var(--default-color); }

	// themes
	// &.gray
	// 	--default-color: #{$nice_color-gray_dark}
	// 	--disabled-color: #{$nice_color-gray_light_semi}
	// 	--hover-color: #{$nice_color-gray_dark_semi}
	// 	--active-color: #{$nice_color-gray_dark}
	// 	--text-color: #{$nice_color-gray_dark}

	// &.navy
	// 	--default-color: #{$nice_color-navy}
	// 	--disabled-color: #{$nice_color-navy_light_ultra}
	// 	--hover-color: #{$nice_color-navy}
	// 	--active-color: #{$nice_color-navy}
	// 	--text-color: #{$nice_color-navy}

	// &.red
	// 	--default-color: #{$nice_color-red}
	// 	--disabled-color: #{$nice_color-red_light_ultra}
	// 	--hover-color: #{$nice_color-red}
	// 	--active-color: #{$nice_color-red}
	// 	--text-color: #{$nice_color-red}

	// &.purple
	// 	--default-color: #{$nice_color-purple}
	// 	--disabled-color: #{$nice_color-purple_light_ultra}
	// 	--hover-color: #{$nice_color-purple}
	// 	--active-color: #{$nice_color-purple}
	// 	--text-color: #{$nice_color-purple}

	// &.blue
	// 	--default-color: #{$nice_color-blue}
	// 	--disabled-color: #{$nice_color-blue_light_ultra}
	// 	--hover-color: #{$nice_color-blue}
	// 	--active-color: #{$nice_color-blue}
	// 	--text-color: #{$nice_color-blue}

	// &.green
	// 	--default-color: #{$nice_color-green}
	// 	--disabled-color: #{$nice_color-green_light_ultra}
	// 	--hover-color: #{$nice_color-green}
	// 	--active-color: #{$nice_color-green}
	// 	--text-color: #{$nice_color-green}

	// &.yellow
	// 	--default-color: #{$nice_color-yellow_dark_semi}
	// 	--disabled-color: #{$nice_color-yellow_light_ultra}
	// 	--hover-color: #{$nice_color-yellow}
	// 	--active-color: #{$nice_color-yellow_dark_semi}
 }	// 	--text-color: #{$nice_color-yellow_dark_semi}

.ni_input--input-row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	> *:not(:first-child):not(.ni_input--autocomplete-button_dd) {
		margin-left: .4em; } }

.ni_input--input {
	flex-grow: 1;
	height: $ni-input-height;
	padding: 0;

	border: none;
	color: var(--text-color);
	font-size: $fsz__new__normal;
	line-height: $txt__line_height;
	overflow: hidden;
	appearance: textfield;


	&::placeholder {
		color: var(--text_2_color); }

	&:disabled {
		background-color: transparent; } }

.ni_input--units {
	// color: var(--text_2_color)
	color: var(--text-color); }

.ni_input--autocomplete-button_dd {
	width: $ni_select--button_dd-size;
	min-height: $ni_select--button_dd-size;
	padding-left: $ni_select--button_dd-indent;
	padding-right: $ni_select--button_dd-indent;

	--ni-icon-sign: #000;
	--ni-icon-sign-stroke-width: 1; }

.ni_input--autocomplete {
	@include shadowed_block;

	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	box-sizing: border-box;
	width: 100%;
	padding: $cmpnt_tippy-indent-y $cmpnt_tippy-indent-x;

	border-radius: 0 0 5px 5px; }

.ni_input--autocomplete-item {
	@include button__clear;

	margin: 4px 0;
	min-height: auto;
	padding: 0 0;
	width: 100%;

	text-align: left;
	text-transform: none;

	&:first-child {
		margin-top: 0; }

	&:last-child {
		margin-bottom: 0; }

	&.empty {
		text-align: center;
		// color: var(--text_2_color)
		color: var(--default-color);
		text-transform: none; }

	&:hover {
		background-color: #{$nice_color-gray_light}; } }
