.notification-loading-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;


  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid var(--main_color);
    border-radius: 50%;
    animation: notification-loading-ring-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--main_color) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s; }

    &:nth-child(2) {
      animation-delay: -0.3s; }

    &:nth-child(3) {
      animation-delay: -0.15s; } } }

@keyframes notification-loading-ring-animation {
    0% {
      transform: rotate(0deg); }

    100% {
      transform: rotate(360deg); } }
