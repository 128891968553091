$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';















































































































































































$width-input: 385px;
$welcome-message-line-compensation: 5px;
$welcome-message-margin-top: $cmpnt-rc__content-margin-top - $ni_button-padding-y - $welcome-message-line-compensation;


.campaign-form_model-select {
	margin: $welcome-message-margin-top auto;
	max-width: $cmpnt-rc__content-width;
	padding-bottom: $welcome-message-margin-top; }

.welcome-message {
	margin: 0 auto $welcome-message-margin-top;
	font-size: $fsz__new__section-title;
	line-height: (19/16); }


.card-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly; // space-between
	align-items: stretch;
	align-content: space-between; }


.payment-model {
	@include card-wrapper-default;
	width: 246px;

	display: inline-block;
	display: flex;
	flex-direction: column;
	align-items: center; }


.payment-model__icon {
	width: 78px;
	height: 78px;
	display: block;
	margin: 0 auto 15px;
	color: var(--main_color); }


.payment-model__title,
.payment-model__goal {
	font-size: $fsz__new__section-title;
	line-height: (19/16);
	margin: 0 auto 20px;
	font-weight: normal; }


.payment-model__title {
	text-align: center;
	color: var(--main_color); }


.payment-model__goal-label {
	display: block;
	margin: 0 auto 4px;
	text-align: center;
	font-size: 10px;
	line-height: 12px;
	color: var(--text_2_color);
	text-transform: uppercase; }


.payment-model__goal {
	text-align: center;
	min-height: (19 / 16 * 2em); }


.payment-model__description {
	color: var(--text_2_color);
	text-align: center;
	font-size: $fsz__new__smaddle;
	line-height: 150%;
	margin-bottom: 32px; }


.payment-model__select-button {
	display: block;
	width: 100%;
	margin-top: auto; }

// .payment-model__select-button_active,
// 	&, &:hover
// 		color: white
// 		background-color: var(--main_color)
// 		border-color: var(--main_color)
