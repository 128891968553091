$table__row-head-height: 50px;
$table__row-body-height: 40px;
$table__cell-padding-x: 20px;

.nice-table {
	display: table;

	border-collapse: collapse;
	color: var(--text_2_color);

	thead {
		tr {
			min-height: $table__row-head-height; }

		td,
		th {
			padding: calc((#{$table__row-head-height} - #{$txt__line_height}) / 2)  $table__cell-padding-x; } }

	tbody {
		tr {
			min-height: $table__row-body-height;

			td,
			th {
				&:first-child {
					// border-radius: 10px 0 0 10px
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px; }

				&:last-child {
					// border-radius: 0 10px 10px 0
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px; } }


			&:nth-child(2n-1) {
				td,
				th {
					background-color: $nice_color-gray_light_ultra-2; } }

			&:hover {
				td,
				th {
					background-color: var(--disabled_color); } } }

		td,
		th {
			padding: calc((#{$table__row-body-height} - #{$txt__line_height}) / 2)  $table__cell-padding-x; } }

	th {
		font-size: $fsz__tiny;
		font-weight: normal;
		line-height: $txt__line_height;
		text-align: left;
		text-transform: uppercase; }

	td {
		font-size: $fsz__smaddle;
		line-height: $txt__line_height; }


	&.shaded {
		> tbody {
			> tr {
				&:nth-child(2n - 1) {
					background-color: #eeeeee; }

				&:nth-child(2n) {
					background-color: #f3f3f3; }

				&:hover {
					background-color: var(--disabled_color); } } } } }


/**
 * Table with fixed thead and probably scrollable tbody
 *
 * Note:
 * You should set height to <tbody>
 * You should set width for each of cells.
 *
 * Apply `hide_scrollbar` class to <tbody> to hide scrollbar.
 *
 */
.fixed-thead {
	> thead,
	> tbody {
		display: block;
		width: 100%;

		> tr {
			width: 100%;
			display: flex;
			justify-content: stretch;
			align-content: stretch;

			> th,
			> td {
				box-sizing: border-box;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				flex-grow: 0;
				flex-shrink: 0;
				flex-wrap: wrap; } } }

	> tbody {
		overflow: auto;

		&.hide-scrollbar {
			@include hide_scrollbar; } } }


/**
 * Wizard trtable
 */

.wizard-table {
	$wzrd-pad-x: 40px;
	width: 100%;

	tbody {
		@include hide_scrollbar;

		> tr {
			height: 50px;

			&.pagination-row {
				background: none;
				align-items: center;
				justify-content: center; } } }

	td, th {
		&:first-child {
			padding-left: $wzrd-pad-x; }

		&:last-child {
			padding-right: $wzrd-pad-x; }

		> .cell-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start; }

		.cell-icon {
			margin-right: 11px; } } }

.pagination-btn {
	padding: 5px 7px;
	border: 1px solid var(--main_color);
	border-radius: 4.5px; }
