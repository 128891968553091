// BASE VARS //////////////////////////
// indents
$default-indent_y: 30px;
$default-indent_x: 25px;
$indent_x: $default-indent_x;

// FONT FAMILY
$roboto-font-family: 'Roboto';

$font-main: $roboto-font-family;
$font-title: $font-main;
$font-monospace: monospace;

// TEXT
// fsz--
$fsz__base: 13.5px;

$fsz__title: 20 / 13.5 * $fsz__base;
$fsz__section-title: 16 / 13.5 * $fsz__base;
$fsz__large: 14 / 13.5 * $fsz__base;
$fsz__normal: 13.5 / 13.5 * $fsz__base;
$fsz__middle: 12.5 / 13.5 * $fsz__base;
$fsz__smaddle: 12 / 13.5 * $fsz__base;
$fsz__small: 11 / 13.5 * $fsz__base;
$fsz__tiny: 10 / 13.5 * $fsz__base;

$fsz__new__title: 20px / $fsz__base * 1rem;
$fsz__new__section-title: 16px / $fsz__base * 1rem;
$fsz__new__large: 14px / $fsz__base * 1rem;
$fsz__new__normal: 13.5px / $fsz__base * 1rem;
$fsz__new__middle: 12.5px / $fsz__base * 1rem;
$fsz__new__smaddle: 12px / $fsz__base * 1rem;
$fsz__new__small: 11px / $fsz__base * 1rem;
$fsz__new__tiny: 10px / $fsz__base * 1rem;

// txt--
$txt__line_height: 1.2em;
$fsz__line_height-default: $txt__line_height;
$txt__white_space: 0.3em;

// ANIMATIONS
$animation__time: .3s;
$animation__easing: ease;

// BORDER-RADIUS
$border-radius: 10px;
$border-radius-min: 5px;

// MEDIA QUERIES BREAKPOINTS
// mq--
$mq__selected_devices_panel: 900px;



// UI GRID ////////////////////////////
// ui-grid--
$ui-grid__breakpoints: ( xs: 0, sm: 600, md: 960, lg: 1264, xl: 1904 );
$ui-grid__cols: 12;

$ui-grid__mgn-x: $indent_x;
$ui-grid__mgn-top: 15px;



// UI /////////////////////////////////

// base
$ni-label-height: 24px;
$ni-input-height: 30px;


// NICE COMPONENTS
// ni_<component>--

// nice-select
$ni_select--button_dd-indent: 5px;
$ni_select--button_dd-icon_size: 10px;
$ni_select--button_dd-size: $ni_select--button_dd-icon_size + $ni_select--button_dd-indent * 2;

$ni_select--options-padding_y: 7px;
$ni_select--option-font_size: $fsz__new__smaddle;
$ni_select--option-line_height: 2;

// nice-label
$ni_label-font-size: $fsz__tiny;
$ni_label-height: $ni-label-height;

// nice-progress-bar
$ni_progress_bar-label-height: $ni_label-height;

$ni_progress_bar-bar-height: 4px;
$ni_progress_bar-bar-margin-y: ( $ni_progress_bar-label-height - $ni_progress_bar-bar-height ) / 2 - 1px;
$ni_progress_bar-bar-transition-time: $animation__time;
$ni_progress_bar-bar-transition-easing: $animation__easing;

// buttons
$ni_button-padding-x: 13px;
$ni_button-padding-y: 6px;
$ni_button-border-width: 2px;
$ni_button-border-radius: 5px;

// nice-slider
$ni_slider-input-fontsize: $fsz__normal;
$ni_slider-input-height: $ni_label-height;

$ni_slider-bar-height: 2px;
$ni_slider-bar-transition-time: $animation__time;
$ni_slider-bar-transition-easing: $animation__easing;

$ni_slider-marker-halo-size: 28px;
$ni_slider-marker-hover-size: 16px;
$ni_slider-marker-size: 12px;
$ni_slider-marker-hover-transition-time: .1s;
$ni_slider-marker-hover-transition-easing: $animation__easing;

// nice-menu-context
$ni_menu_context--margin: .5em;

// nice-action-list
$ni_action_list--icon-status: 16px;
$ni_action_list--icon-button: 24px;



// COMPONENTS /////////////////////////
// $cmpnt_<name>--

// request create
$cmpnt-rc__content-width: 805px;
$cmpnt-rc__content-margin-top: 80px;
$cmpnt-rc__content-margin-default: 30px;


// tippy
$cmpnt_tippy-indent-x: 12px;
$cmpnt_tippy-indent-y: 11px;


// LAYOUT /////////////////////////////

// CENTERED
$layout-center805-width: 805px;

// FORM LAYOUT
$form-row-indent-y: 20px;
$form-label-height: $ni-label-height;
$form-input-height: $ni-input-height;
$form-row-height: $form-label-height + $form-input-height;











// OLD VARIABLES //////////////////////////////////////////////////////////////////////////////////



// UI NICE THEME
// ui-nice__
////////////
$ui-nice__label-height: $ni_label-height;
$ui-nice__label-margin_bottom: 2px;
$ui-nice__labeled-without-label-padding-top: $ui-nice__label-height + $ui-nice__label-margin_bottom;

$ui-nice__input-heigth: 30px;




// DEPRECATE !!!!
// PALETTE
// clr__
//////////

$clr__default: $nice_color-default;

// gray
$clr__lightgray: $nice_color-lightgray;
$clr__gray: $nice_color-gray;
$clr__semidarkgray: $nice_color-semidarkgray;
$clr__darkgray: $nice_color-darkgray;

$clr__proposed: $nice_color-proposed;
$clr__booked: $nice_color-booked;

$clr__button: $nice_color-button;
$clr__activebutton: $nice_color-activebutton;
