$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';










































































































































































































































































































































































































































































































































































































































































































































.app-settings-page {
	padding-bottom: 50px; }

.panel {
	margin: 40px; }

h1 {
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 30px;
	font-weight: normal; }

h2 {
	font-size: 16px;
	line-height: 21px;
	margin-top: 30px;
	margin-bottom: 30px;
	font-weight: normal; }

.panel-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 40px; }

.settings {
	display: flex;
	flex-direction: column; }


.input-field {
	margin-bottom: 30px; }

.select-field {
	margin-bottom: 30px;
	width: 140px; }

.certificate-info {
	font-size: 12px;
	line-height: 14px;
	color: $nice_color-darkgray;
	margin-top: -25px; }

.help-info {
	font-size: 12px;
	line-height: 14px;
	color: $nice_color-darkgray;
 }	// margin-top: -25px

.image-info {
	font-size: 12px;
	line-height: 14px;
	color: $nice_color-darkgray;
	margin-top: 10px; }

.icon-uploader {
	margin-top: 24px; }

.input-color {
	display: flex;
	flex-direction: row;
	margin-top: 24px; }

.color-field {
	// margin-bottom: 30px
	width: 140px;

	&::v-deep .ni_input--input {
		padding-left: 12px; }

	&::before {
		content: '#';
		color: $nice_color-darkgray;
		position: absolute;
		bottom: 7px; } }

.drop-icon {
	width: 40px;
	height: 48px;

	margin-right: 20px;
	padding-top: 10px; }

.tos-file-uploader-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end; }

.tos-label {
	margin-bottom: 10px; }

.tos-textarea {
	resize: none; }

.tos-preview {
	width: 362.49px;
	height: 105px;

	font-style: normal;
	font-weight: normal;
	font-size: 10.5px;
	line-height: 140%;

	color: #919191; }

.tos-file-uploader {
	height: 32px;
	width: fit-content; }

.tos-file-preview {
	font-size: 12px;
	line-height: 14px;
	color: $nice_color-darkgray;
	margin-bottom: 10px; }

.dns-settings-info {
	color: $nice_color-darkgray; }

.input--duration {
	width: 132px; }

.duration-n-rate {
	display: flex;
	flex-direction: row;

	.frequency-limit {
		margin-left: 40px;
		width: 42px;

		&::before {
			content: '<';
			color: $nice_color-darkgray;
			position: absolute;
			bottom: 7px;
			left: -10px; } } }

.ad-rate__input {
	display: flex;
	flex-direction: row; }

.ad-rate__label {
	margin-left: 40px; }

.ad-rate__modifier {
	margin-left: 10px; }

.input--time-range {
	margin-bottom: 20px; }
