$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';

























































































































































































































































































.summary-stats {
	@include card-wrapper-default;
 }	// width: 275px

.summary-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	min-height: 30px; }

h3 {
	font-size: 16px;
	line-height: 19px;
	font-style: normal;
	font-weight: normal;
	margin: 0;
	min-height: 30px; }

.summary-body {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr; }

.summary-value {
	font-size: 16px;
	line-height: 19px;

	color: #2DC3B7; }

.summary-body-element {
	display: flex;
	flex-direction: column;

	&:not(:first-child) {
		margin-left: 10px; } }

.header-details {
	height: $ni_label-height;
	font-size: $ni_label-font-size;
	line-height: $ni_label-height;
	color: var(--text_2_color);
	font-family: inherit;
	text-overflow: ellipsis;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden; }

.hr {
	margin-bottom: 21px; }

.cost {
	color: var(--text_color);

	span {
		color: var(--text_2_color);
		display: inline-block;
		width: 40px; } }

.title-wrapper {
	display: flex;
	align-items: baseline; }

.title-line-link {
	color: var(--main_color);
	text-decoration: none;
	font-weight: bold;
	font-size: 11px;
	line-height: 13px;
	margin-left: auto; }



.nice-progress-bar {
	width: 100%; }

.table-specs {
	width: 100%;

	thead th {
		padding: 10px 0; }

	tbody {
		tr {
			height: 25px;

			td {
				padding: 5px 0;
				color: var(--text_color);
				background-color: transparent;

				> * {
					vertical-align: middle; } } } }

	.col-icon {
		text-align: center;
		width: 16px; }

	.col-text {
		padding-left: 10px; } }

.bad-uptime {
	color: #919191; }

.uptime-hint {
	display: flex;
	flex-direction: row;

	justify-content: space-between; }
