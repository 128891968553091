$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';





























































































































































































































































































































































































































































main {
	margin-bottom: 110px; }

$margin-left: 90px;
.filter-n-chart-container {
	position: relative;
	display: flex;
	flex-direction: row;

	.filter,
	.chart {
		flex: 1;
		flex-basis: 50%; }

	.filter {
		display: none;
		margin: 20px 0px;
		margin-left: $margin-left;

		&.opened {
			display: flex;
			min-width: calc(50% - #{$margin-left});

			+ .chart {
				margin-left: 20px; } } }

	.chart {
		margin: 20px 45px;
		margin-left: $margin-left; } }

.toggle-filter {
	$height: 30px;

	position: absolute;
	top: 50%;
	left: 0;
	transform: rotate(-90deg);

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0;
	height: $height;
	padding: 0 22px 0 20px;

	background: $nice_color-blue;
	border-radius: 15px;
	border: none;
	color: white;
	cursor: pointer;
	text-transform: uppercase;
	outline: none;

	// &:focus
	// 	box-shadow: 0 0 0 1px $nice_color-darkgray

	&.opened {
		background: $nice_color-activebutton;
		color: $nice_color-blue;

		// &:focus
 }		// 	box-shadow: 0 0 0 1px $nice_color-blue

	.nice-icon {
		margin-right: 8px; } }

.nice-checkbox,
.select-all .nice-icon {
	display: inline-block;
	vertical-align: bottom;
	margin-bottom: 0px;
	margin-right: 6px; }


.devices-table {
	width: 100%;
	margin-top: 40px;

	td {
		.nice-icon {
			display: inline-block;
			vertical-align: middle;
			margin-right: 6px; } } }


.map {
	height: 500px;
	padding-bottom: $default-indent_y; }


.network-paginator {
	width: auto; }


.select-all {
	@include button__clear;
	margin-right: 6px;

	.nice-icon {
		margin-right: 0; }

	.state-checked.page {
		padding-top: 0; } }

circle {
	fill: red !important; }

.select-button {
	@extend %button__text;

	display: block;
	padding: 5px 5px;
	font-size: 11px; }

.hidden-map-for-geocoder {
	visibility: hidden;
	width: 0px;
	height: 0px; }

::v-deep {
	.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
		visibility: hidden;
		display: none; }

	.select_areas {
		width: 100%; } }
