$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';































































































































.nice-progress-bar {
	--progress: 0;
	--hint: 0;
	width: 250px;
	font-size: $fsz__normal;

	&.label_holder {
		padding-top: $ni_progress_bar-label-height; } }

.ni_progress_bar--label {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between; }

.ni_progress_bar--value_row {
	$padding-top: 4px;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;

	width: 100%;
	height: auto;
	min-height: $ni_progress_bar-label-height - $padding-top;
	padding: $padding-top 0 0;

	font-size: inherit;
	color: var(--text_color); }

.ni_progress_bar--value {
	display: flex;
	align-items: center;
	font-size: inherit;
	line-height: $fsz__line_height-default; }


.ni_progress_bar--value_icon {
	margin-right: 3px; }


.ni_progress_bar--bar {
	position: relative;

	width: 100%;
	height: $ni_progress_bar-label-height;

	&::before {
		content: "";

		position: absolute;
		top: $ni_progress_bar-bar-margin-y;
		left: 0;

		display: block;
		height: $ni_progress_bar-bar-height;
		width: 100%;

		background-color: var(--disabled_color);
		border-radius: $ni_progress_bar-bar-height / 2; }

	&::after {
		content: "";

		position: absolute;
		top: $ni_progress_bar-bar-margin-y;
		left: 0;

		display: block;
		height: $ni_progress_bar-bar-height;
		width: var(--progress);

		background-color: var(--main_color);
		border-radius: $ni_progress_bar-bar-height / 2;

		transition: width $ni_progress_bar-bar-transition-time $ni_progress_bar-bar-transition-easing; } }

.ni_progress_bar--hint {
	position: absolute;
	left: var(--hint);
	top: 6px;
	width: 0;
	height: 12px;
	z-index: 1;
	transition: left $ni_progress_bar-bar-transition-time $ni_progress_bar-bar-transition-easing; }

.progress-marker {
	position: absolute;
	display: block;
	left: -5px;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 1px solid #ffffff;
	background-color: var(--main_color);
	box-sizing: border-box;

	&::before {
		background-color: inherit;
		will-change: opacity;
		content: '';
		display: block;
		z-index: -1;
		position: absolute;
		width: 20px;
		height: 20px;
		top: -6px;
		left: -6px;
		opacity: 0;
		transition: opacity .2s ease;
		border-radius: 50%; }

	&:hover::before {
		opacity: .4; } }

