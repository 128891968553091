$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';
















































































































































































































































































































































































































































































































































































$padding-y: 5px;
$axis-margin-y: 10px;
$axis-width: 2px;
$marker-halo-size: 28px;
$marker-hover-size: 16px;
$marker-size: 12px;

$axis-color: $nice_color-gray;
$range-color: $nice_color-blue;

$range-disabled-color: $nice_color-darkgray;

$scale-padding: 12px;

$legend-color: $nice_color-gray;


.nice-range {
	--padding-y: #{$padding-y};
	--axis-margin-y: #{$axis-margin-y};
	--axis-width: #{$axis-width};
	--marker-halo-size: #{$marker-halo-size};
	--marker-hover-size: #{$marker-hover-size};
	--marker-size: #{$marker-size};

	--axis-color: #{$axis-color};
	--range-color: var(--secondary_color);

	--scale-padding: #{$scale-padding};
	--scale-marker-color: var(--axis-color);
	--scale-marker-width: 0px;
	--scale-marker-height: 0px;
	--scale-label-width: 5em;
	--scale-label-font-size: 13.5px;
	--scale-label-line-height: 16px;

	--legend-color: #{$legend-color};

	position: relative;
	padding: var(--padding-y) 0;
	z-index: 0;

	font-size: 13.3px;
	line-height: 16px;

	&.disabled {
		--axis-color: #{$axis-color};
		--range-color: var(--secondary_disabled_color); }

	&.demo {
		--axis-color: transparent;
		--axis-width: 0; } }

.ni-axis {
	position: relative;
	width: 100%;
	height: var(--axis-width);
	background-color: var(--axis-color);
	cursor: pointer;
	border-radius: calc(var(--axis-width) / 2);
	margin: var(--axis-margin-y) 0;

	.scaled & {
		margin-top: calc(var(--scale-padding) + var(--scale-label-line-height)); } }

.ni-range {
	--a-shift: 0;
	--b-shift: 0;
	will-change: width, left, right;
	position: absolute;
	left: var(--a-shift);
	width: calc(var(--b-shift) - var(--a-shift));
	right: calc(100% - var(--b-shift));
	height: 100%;
	background-color: var(--range-color);

	&.active {}

	&:not(.active) {
		transition: left .3s ease, width .3s ease, right .3s ease; } }


.ni-marker {
	--x-shift: 0;

	will-change: transform, left;
	position: absolute;
	z-index: 0;
	top: calc(50% - var(--marker-size) / 2);
	left: calc(var(--x-shift) - var(--marker-size) / 2);
	display: block;
	width: var(--marker-size);
	height: var(--marker-size);
	background-color: var(--range-color);
	border-radius: 50%;
	cursor: grab;

	&::before,
	&::after {
		will-change: transform, opacity;
		content: "";
		display: block;
		position: absolute;
		border-radius: 50%;
		background-color: var(--range-color); }

	&::before {
		width: var(--marker-hover-size);
		height: var(--marker-hover-size);
		top: calc(50% - var(--marker-hover-size) / 2);
		left: calc(50% - var(--marker-hover-size) / 2);
		transition: transform .1s ease;
		transform: scale3d(.5, .5, 1); }

	&::after {
		width: var(--marker-halo-size);
		height: var(--marker-halo-size);
		top: calc(50% - var(--marker-halo-size) / 2);
		left: calc(50% - var(--marker-halo-size) / 2);
		opacity: 0;
		transition: opacity .1s ease; }

	&:not(.active) {
		transition: left .3s ease; }

	&.active,
	&:hover {
		&::before {
			transform: scale3d(1, 1, 1); }

		&::after {
			opacity: .2; } }

	&.active {
		cursor: grabbing; }

	&.last-active {
		z-index: 1; } }


.ni-scale-marker {

	position: absolute;
	z-index: -1;
	bottom: 0;
	left: calc(var(--x-shift) - var(--scale-marker-width) / 2);
	height: var(--scale-marker-height);
	width: var(--scale-marker-width);

	background-color: var(--scale-marker-color);
	border-radius: calc(var(--scale-marker-width) / 2);

	&.in-range {
		--scale-marker-color: var(--range-color); } }


.ni-scale-label {

	position: absolute;
	display: block;

	bottom: calc(var(--axis-width) + var(--scale-padding));
	left: calc((var(--scale-marker-width) - var(--scale-label-width)) / 2);
	width: var(--scale-label-width);

	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	pointer-events: none; }


.ni-legend-wrapper {
	display: block;
	position: relative;
	height: 1.25em;
	color: var(--legend-color); }

