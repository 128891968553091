$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';












































































































































































































































































































































































































































.counters-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-bottom: 40px;
	min-height: 245px;
	border-radius: 10px;
	border: 1px solid #DDDDDD;
	box-sizing: border-box;
	min-width: fit-content;

	&>* {
		border-right: 1px solid #dddddd;
		&:last-child {
			border: none; } } }

.container {
	padding-left: 15px;
	padding-top: 10px;

	display: flex;
	flex-direction: column;
	padding: 22px 16px; }

.stat-wrapper {
	margin: auto 0; }

.requests {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	column-gap: 15px;
	row-gap: 15px;

	& > hr {
		border: 1px solid #dddddd;
		border-width: 0 0 1px;
		grid-column: span 3;
		margin: 0; } }

.value-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: -7px;
	font-size: 12px; }

.total {
	font-weight: 300;
	font-size: 36px;
	line-height: (42 / 36); }

.total_balance {
	font-weight: 300;
	font-size: 50px;
	line-height: (60 / 50); }

.label-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between; }

.header {
	display: flex;
	align-items: flex-start; }

.label {
	font-size: 13.5px;
	line-height: 16px;
	color: #000000;
	text-decoration: underline;

	margin-bottom: 16px;
	margin-right: 5px; }

.timing {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 35px;
	max-width: 200px; }

.value-wrapper__icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin: -1.5px 5px -1.5px 0;
	vertical-align: auto; }

.value {
	display: flex;
	align-items: center;
	margin-top: 5px; }

.empty-balance-label {
	font-weight: 300;
	font-size: 20px;
	line-height: 23px;

	color: #919191; }

.request-button {
	text-decoration: none; }

.request-button-body {
	width: 150px;
	height: 31px;

	background: rgba(45, 195, 183, 0.2);
	border-radius: 5px;

	display: flex;
	flex-direction: row;
	align-items: center;
	text-transform: uppercase;
	justify-content: center; }

.request-button-text {
	font-weight: bold;
	font-size: 11px;
	line-height: 13px;

	color: #2CC3B7; }

.request-button-icon {
	width: 10px;
	height: 10px;
	margin: -1.5px 8px -1.5px 0;

	align-items: center;
	color: #2DC3B7; }
