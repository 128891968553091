$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';























































































































































































































































.ar_mapbox_popup {
	will-change: unset;
	& ::v-deep {
		.mapboxgl-popup-tip {
 }			// display: none

		.mapboxgl-popup-content {
			@include default__shadow;
			padding: 20px;
			border-radius: 10px; } } }

.popup-body {
	display: flex;
	flex-direction: column;
	width: 250px; }

.row-name {
	display: inline-flex;
	// box-sizing: border-box
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
	width: 100%;

	font-size: $fsz__normal;
	line-height: 17px;
	color: $nice_color-black; }

.name-wrapper {
	display: inline-flex;
	flex-grow: 1;
	flex-shrink: 1;

	.name {
		flex-grow: 1;
		font-family: inherit;
		// vertical-align: top
		font-size: 100%;
		font-weight: bold;
		margin-left: 10px; } }

.nice-button {
	flex-grow: 0;
	flex-shrink: 0;

	$padding: 5px;
	margin: (-$padding + 1) (-$padding + 1) 0 0;
	min-height: auto;
	height: 20px;
	padding: $padding;
	margin-left: 20px;
	// line-height: 0
	// height: 10px

	.nice-icon {
		margin-right: 0; }

	.nice-icon {
		vertical-align: top;
		margin-right: .5em; } }


.address {
	padding: 10px 0;
	font-family: inherit;
	font-size: 100%;
	// line-height: $fsz__large
	color: $nice_color-graydark; }

.count-screen {
	padding-bottom: 10px;
	font-family: inherit;
	font-size: 100%;
	color: $nice_color-graydark; }

.address-with-count-screen {
	padding-bottom: 0; }

.control {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-top: 8px;
	margin-bottom: 0;


	&:first-child {
		margin-left: 0; } }

.device-action-button {
	margin-left: 0px; }

.close-button {
	max-width: 40px; }
