$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';









































































































































































































































































































































































































































































































































































































$width-input: 385px;

.message {
	font-size: 24px;
	font-weight: 100; }

.message_no-device {}

.format-title {
	// display: flex
	// align-items: center
	margin-bottom: 26px; }

.format-title__icon {
	display: inline-block;
	margin-right: 7px;
	vertical-align: middle; }

.nice-icon__resolution-14 {
	&.state-90 {
		margin-top: -3px; } }


.format-title__count {
	color: var(--text_2_color); }

.inline-button {
	min-height: 0px; }

.privacy-policies-line {
	line-height: 1.15;
	text-align: right; }

.campaign-creative {
	margin: $cmpnt-rc__content-margin-top auto 0;
	max-width: $cmpnt-rc__content-width; }

.creative-wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px; }

.creative-preview {
	// & + .creative-preview
	// 	margin-top: 15px

	&:nth-of-type(even) {
		background: none; } }


.nice-input {
	margin: 0 auto 40px;
	max-width: $width-input;
	width: 100%;

	&:last-child {
		margin-bottom: 0; } }

.next-button {
	align-self: flex-end; }

.creative-specs {
	margin-bottom: 80px;
	font-size: $fsz__normal;

	.nice-button {
		padding-left: 0; } }

.final-line {
	margin-bottom: 80px;
	display: flex;
	position: relative;
	justify-content: flex-end;
	align-items: center;

	> .privacy-policies-line {
		font-size: $fsz__normal;
		color: $nice_color-gray_dark;
		margin-right: 30px; }

	> .button-separator {
		margin: 0 10px; } }

.inline-button {
	padding-left: 0;
	padding-right: 0; }

.final-line__hint {
	margin-left: 10px; }

.inline-link {
	color: var(--main_color);
	text-decoration: none;
	text-transform: uppercase;
	font-weight: bold;
	font-size: smaller;

	&:hover {
		text-decoration: underline; } }

.creative-wrapper-other .creative-preview-wrapper {
    margin-bottom: 10px; }

.attachments-list {
	margin-top: 0; }

.attachments-list li a {
	color: var(--main_color);
	text-decoration: none;
	margin-right: 1px; }

.attachments-list li a:hover,
.attachments-list li a:focus,
.attachments-list li a:active {
	color: var(--main_color); }

.attachment-basket {
	position: relative;
	top: 3px;
	cursor: pointer; }
