/*
 * https://kabbouchi.github.io/vue-tippy/2.1/features/themes.html
 */

$tippy-theme-name: 'nice';

// Tippy custom theme
/////////////////////
.tippy-popper:focus {
	outline: none; }

.tippy-tooltip.#{$tippy-theme-name}-theme {
	$font_size: $fsz__middle;

	// $def_height: 31px
	$line_height: 1.25;  // default line-height
	$pad_x: $cmpnt_tippy-indent-x;
	$pad_y: $cmpnt_tippy-indent-y;  // ($def_height - $font_size * $line_height) / 2

	color: var(--text_color);
	font-size: $font_size;
	line-height: $line_height;
	padding: $pad_y $pad_x;

	@include shadowed_block; }

.tippy-tooltip.#{$tippy-theme-name}-theme {
	.tippy-arrow {
		$arrow_length: 8px;
		$arrow_width: 13px;

		.tippy-popper[x-placement^='top'] & {
			border-left: $arrow_width / 2 solid transparent;
			border-right: $arrow_width / 2 solid transparent;
			border-top: $arrow_length solid #ffffff; }

		.tippy-popper[x-placement^='right'] & {
			border-top: $arrow_width / 2 solid transparent;
			border-bottom: $arrow_width / 2 solid transparent;
			border-right: $arrow_length solid #ffffff; }

		.tippy-popper[x-placement^='bottom'] & {
			border-left: $arrow_width / 2 solid transparent;
			border-right: $arrow_width / 2 solid transparent;
			border-bottom: $arrow_length solid #ffffff; }

		.tippy-popper[x-placement^='left'] & {
			border-top: $arrow_width / 2 solid transparent;
			border-bottom: $arrow_width / 2 solid transparent;
			border-left: $arrow_length solid #ffffff; } } }

.tippy-popper[data-html] {
	max-width: 300px;

	.tippy-content {
		text-align: left; } }
