$nice_color-main_color: #2dc3b7;
$nice_color-main_lighter_color: #2dc3b750;
$nice_color-disabled_color: #2dc3b733;
$nice_color-secondary_color: #0263cc;
$nice_color-secondary_disabled_color: #0263cc33;
$nice_color-gray_dark_ultra: #000000;
$nice_color-gray_dark: #919191;
$nice_color-gray_dark_semi: #ababab;
$nice_color-gray: #c1c1c1;
$nice_color-gray_light_semi: #dddddd;
$nice_color-gray_light: #e9e9e9;
$nice_color-gray_light_ultra-2: #f7f7f7;
$nice_color-gray_light_ultra: #ffffff;
$nice_color-cyan: #2dc3b7;
$nice_color-cyan_light: rgba(45, 195, 183, .2);
$nice_color-cyan_lighter: rgba(45, 195, 183, .3);
$nice_color-cyan_light_ultra: #e0f6f4;
$nice_color-green: #21cf52;
$nice_color-green_light_ultra: rgba(33, 207, 82, .2);
$nice_color-yellow_dark_semi: #eac000;
$nice_color-yellow: #ffd100;
$nice_color-yellow_light_ultra: rgba(234, 192, 0, .2);
$nice_color-red: #ff6236;
$nice_color-red_light_ultra: #ffe0d7;
$nice_color-purple: #7c5ecf;
$nice_color-purple_light_ultra: #e5dff5;
$nice_color-blue: #0263cc;
$nice_color-blue__disabled: #0263cc33;
$nice_color-blue_light_ultra: #cce0f5;
$nice_color-navy: #1a2e46;
$nice_color-navy_light_ultra: #d1d5da;
$nice_color-black: #000000;
$nice_color-default: #232323;
$nice_color-gray-dark: #919191;
$nice_color-graydark: #919191;
$nice_color-darkgray: #919191;
$nice_color-gray-semidark: #ababab;
$nice_color-graysemidark: #ababab;
$nice_color-semidarkgray: #ababab;
$nice_color-black__disabled: #b2b2b2;
$nice_color-grayest: #dddddd;
$nice_color-graydark__disabled: #dedede;
$nice_color-darkgray__disabled: #dedede;
$nice_color-graylight: #e1e1e1;
$nice_color-lightgray: #e1e1e1;
$nice_color-grayest__disabled: #e1e1e1;
$nice_color-semidarkgray__disabled: #e1e1e1;
$nice_color-graysemidark__disabled: #e1e1e1;
$nice_color-gray-light: #e9e9e9;
$nice_color-gray__disabled: #ececec;
$nice_color-graylightest: #f7f7f7;
$nice_color-white: #ffffff;
$nice_color-white__disabled: #ffffff;
$nice_color-activebutton: #cde0f5;
$nice_color-bluelight: #9ac1ec;
$nice_color-blue_dark: #1a2e46;
$nice_color-blue-gray: #d1d5da;
$nice_color-proposed: #1a2e46;
$nice_color-blue_dark__disabled: #424f5e;
$nice_color-booked: #0263cc;
$nice_color-button: #0263cc;
$nice_color-greenligth: #c0ede9;
$nice_color-ar_green: #2dc3b7;
$nice_color-ar_green__disabled: #c0ede9;
$nice_color-green-ultra-light: #e0f6f4;
$nice_color-red-light: #ffe0d7;
$nice_color-red__disabled: #ffc4b4;
$nice_color-purple-light: #e5dff5;
$nice_color-purple__disabled: #e5dff5;
@import '@/assets/styles/mixin.sass';
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































$column_indent: 45px;
$row--indent: 20px;
$row--width: 330px;

h3 {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;

	.nice-hint {
		margin-left: 7px; } }

.map {
	height: 372px;
	margin-bottom: $cmpnt-rc__content-margin-default; }


.advanced-settings-switch {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
	background-color: var(--main_lighter_color);
	border-radius: 10px;
	// margin-top: 30px
	margin-bottom: 50px;

	&.disabled {
		background-color: #F5F5F5;

		&:hover {
			background-color: var(--main_lighter_color);

			label {
				color: var(--main_color); } }

		label {
			color: #919191; } }

	label {
		margin: 0 20px 0 20px;
		padding: 0;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: var(--main_color); }

	label.label {
		flex-grow: 1;
		padding-top: 10px;
		padding-bottom: 10px; } }

.model-select-line {
	position: relative;
	font-weight: bold;
	font-size: 14.5px;
	line-height: (17 / 14.5 * 1em);
	padding-left: 60px;
	margin-top: 50px;
	margin-bottom: 50px;
	box-sizing: border-box; }


.model-select-line__icon {
	position: absolute;
	display: block;
	width: 39px;
	height: 39px;
	left: 0;
	color: var(--main_color);
	top: calc((100% - 39px) / 2); }

.model-select-line__inline-dd {
	display: inline-block;
	font-size: inherit;
	line-height: inherit;
	border-bottom: 2px solid var(--main_color);

	::v-deep {
		.ni_menu_context--button-toogle-dropdown {
			font-size: inherit;
			font-weight: inherit;
			text-transform: inherit;
			line-height: inherit; } } }

.model-select-line__option {
	font-size: inherit;
	line-height: inherit; }

.model-select-line__inline-input {
	display: inline-block;
	text-align: right;
	appearance: textfield;
	border: none;
	border-bottom: 2px solid var(--main_color);
	color: var(--main_color);
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	width: 4.5em;
	margin: 0 .5em;
	padding: 0; }

.model-select-line__error-icon {
	vertical-align: middle;
	margin-left: .4em; }

.request_create-step03--card {
	position: sticky;
	top: 20px; }

.data_block {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;

	margin-top: $cmpnt-rc__content-margin-default;
	margin-bottom: $cmpnt-rc__content-margin-default * 1.3;

	> * {
		margin-bottom: $row--indent;

		&:first-child {
			margin-top: 0; }

		&:last-child {
			margin-bottom: 0; } }

	h3 {
		font-weight: normal;
		font-size: $fsz__new__section-title; }

	.nice-spoiler {
		box-sizing: border-box;
		width: 100%;
		max-width: 380px;
		margin: 0;

		&.ni_slider--container {
			margin-top: $row--indent / 2;
			margin-bottom: $row--indent / 2; }

		&.nice-spoiler-platforms {
			margin-bottom: $row--indent / 2; } } }

.platform-select,
.time_period {
	max-width: $row--width; }

// .select_areas
// 	margin-right: $column_indent


.input--budget,
.input--max_bid {
	max-width: 225px;

	::v-deep {
		.ni_input--input,
		.ni_input--units {
			// margin-top: 30px
			font-size: $fsz__title;
			font-weight: 300; } } }

.input--help_text {
	margin-top: 0;

	font-size: $fsz__section-title;
	color: var(--text_2_color); }

.input--description {
	margin-top: 0;
	margin-bottom: 30px; }

.next_button {
	width: 100%;
	height: 40px;
	margin-bottom: 10px; }

.control-row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	// margin-right: $column_indent
	align-items: center;

	& > * {
		margin-right: 15px; } }

.control-row__message {
	display: inline-block;
	// color: var(--text_2_color)
	color: $nice_color-red; }

.control-row__message_errors {
	color: $nice_color-red; }

.input--ad_flight::v-deep {
	border-bottom: transparent; }


.aside {
	position: sticky;
	top: 40px;
	box-sizing: border-box;
	padding-left: 20px; }

.aside__button-wrapper {
	display: flex;
	align-items: center;
	margin-top: 20px;

	& > * {
		margin-right: 10px;
		&:last-child {
			margin-right: 0; } } }

.aside__modal-button {
	flex-basis: 100%; }


.input--duration {
	width: 132px; }

.duration-n-rate {
	display: flex;
	flex-direction: row;

	.frequency-limit {
		margin-left: 40px;
		width: 42px;

		&::before {
			content: '<';
			color: $nice_color-darkgray;
			position: absolute;
			bottom: 7px;
			left: -10px; } } }

.inventory-modal {
	& ::v-deep > * {
		max-width: 1320px; } }
//
// Deep
//

::v-deep {
	.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
		visibility: hidden;
		display: none; }

	.ni_input--autocomplete {
		width: 200%; }

	.ni_input--units {
		color: var(--text_2_color); } }

.fixed-placement {
	display: flex;
	flex-direction: row;
	align-items: center;

	& > * {
		margin-right: 10px; } }

.fixed-placement__label {
	margin-bottom: 5px;

	font-size: 16px;
	line-height: 19px; }

.fixed-placement__hint {
	margin-bottom: 5px; }

.fixed-cost__container {
	display: flex;
	flex-direction: column; }

.fixed-cost__value {
	width: 81px;
	height: 23px;

	font-weight: 300;
	font-size: 20px;
	line-height: 23px; }

.fixed-cost__units {
	color: #919191;
	margin-left: 5px; }

.ad-rate__input {
	display: flex;
	flex-direction: row; }

.ad-rate__label {
	margin-left: 40px; }

.ad-rate__modifier {
	margin-left: 10px; }
