// LAYOUT /////////////////////////////

// full width
%layout-full_width-block {
	margin-left: $default-indent_x;
	margin-right: $default-indent_x;
	width: calc(100% - #{$default-indent_x} * 2);

	&:first-child {
		margin-top: $default-indent_y; }

	&:last-child {
		margin-bottom: $default-indent_y; } }

// center with max size 805
%layout-center805-block {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: $layout-center805-width;

	&:first-child {
		margin-top: $default-indent_y; }

	&:last-child {
		margin-bottom: $default-indent_y; } }








// HIDE SCROLLBAR //
@mixin hide-scrollbar {
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none; } }


// BUTTONS //

// clear
@mixin button__clear {
	display: inline-block;
	margin: 0;
	padding: 0;

	appearance: none;
	background: none;
	border: none;
	color: inherit;
	cursor: pointer;
	font-size: inherit;
	line-height: inherit;
	text-transform: uppercase;
	user-select: none; }

// default text button
%button__text {
	@include button__clear;

	color: var(--main_color); }


%button__text-in-border {
	// +button__clear
	padding: 6px 17px;
	border: 2px solid var(--main_color);
	border-radius: 5px;
	color: var(--main_color); }


%button__popover {
	@extend %button__text;
	padding: 5px 5px;
	font-size: 11px; }

%popover__button-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start; }


// BORDERS //
@mixin default__border {
	border: 1px solid $nice_color-gray_light;
	border-radius: 10px; }


// SHADOWS //

// dropdown menu
@mixin default__shadow {
	box-shadow: 0 4px 10px rgba(#000000, .2); }

@mixin menu__shadow {
	@include default__shadow;
 }	// box-shadow: 0 5px 18px rgba(0, 0, 0, .18)

@mixin shadowed_block {
	@include default__shadow;

	border-radius: 10px;
	background: $nice_color-white; }



////////////////
// COMPONENTS //
////////////////

// Cards - карточки просмотра пакета / предложения и иже с ним //
// card wraper
@mixin card-wrapper-default {
	@include default__border;

	// width: 246px
	padding: 25px;
	box-sizing: border-box;

	font-size: $fsz__normal; }

/////////////
// NICE UI //
/////////////

// компонент
%ui-nice-component {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	font-size: $fsz__normal;
	line-height: $ui-nice__input-heigth;
	box-sizing: border-box;

	&.labeled {
		padding-top: $ui-nice__labeled-without-label-padding-top; } }

%ui-nice-label-standalone {
	display: block;
	height: $ui-nice__label-height;
	max-width: 100%;

	color: var(--text_2_color);
	font-size: $fsz__tiny;
	line-height: $ui-nice__label-height;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
	white-space: nowrap; }

%ui-nice-label {
	@extend %ui-nice-label-standalone;

	position: absolute;
	top: 0;
	left: 0; }


// сам инпут / селект

%ni-value {
	display: inline-block;
	height: $ui-nice__input-heigth;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	overflow: hidden; }

%ui-nice-field {
	@extend %ni-value;
	display: block;
	border: none;
	padding: 0;
	margin: 0;
	background: none;
	appearance: none;
	align-self: stretch;
	justify-self: stretch;
	flex-grow: 1;

	&::placeholder,
	& > .ni-placeholder,
	& > .placeholder {
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
		color: $clr__darkgray;
		user-select: none;
		transition: opacity .2s ease; }

	&:focus::placeholder,
	&:focus > .ni-placeholder,
	&:focus > .placeholder {
		opacity: 0; } }

%ui-nice-units {
	display: inline-block;
	white-space: nowrap;
	margin-left: .5em;
	margin-right: .5em;
	overflow: hidden;
	flex-shrink: 0;
	text-overflow: ellipsis;
	color: $clr__darkgray; }
