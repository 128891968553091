.nice-input-old {
	@extend %ui-nice-component;
	border-bottom: 1px solid $nice_color-gray; }

.nice-value-old {
	@extend %ui-nice-component;

	// DD, and you ask me
	// `Cho ty s etogo tak bombish?`
	// Look at this shit:
	> .ni-value,
	> .ni-input-units {
		font-weight: 300; }

	&.md {
		font-size: 16px; }

	&.lg {
		font-size: 18px; }

	&.xl {
		font-size: 26px;

		> .ni-label {
			font-size: 11px; } } }

.nice-label-old {
	@extend %ui-nice-label-standalone; }

.ni-label-old {
	@extend %ui-nice-label; }

.ni-value-old {
	@extend %ni-value; }

.ni-input-old {
	@extend %ui-nice-field; }

.ni-input-units-old {
	@extend %ui-nice-units; }
